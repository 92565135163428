/* eslint-disable */
if (navigator.serviceWorker) {

  const clientLibsPath = `${location.protocol}//${location.hostname}${document.documentElement.dataset.publicpath}`;
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register(`${clientLibsPath}service-worker.js`, {
        scope: clientLibsPath
      })
      .then(registration => {
        console.debug('[Workbox] Service worker registration successful, scope is:', registration.scope);
      })
      .catch(error => {
        console.warn('[Workbox] Service worker registration failed, error:', error.message);
      });

  });
}
